import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Helper from 'eventtia-ui-components/lib/Helper';
import PersonListCard from '../PersonListCard';
import { getCurrentPerson, getCurrentAttendee } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  listViewHeader: {
    zIndex: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: 55,
    height: 44,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: theme.customShadows.default,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  gridThreeColumns: {
    gridTemplateColumns: '3fr 2fr 1fr',
  },
  gridFourColumns: {
    gridTemplateColumns: '3fr 1fr 1fr 150px',
  },
  dataTitle: {
    textWrap: 'nowrap',
    textAlign: 'start',
    fontSize: 14,
    color: theme.palette.darkGrey.light,
  },
}));

const ListViewTable = ({
  attendeeTypeCustomFields, attendeeTypes, attendees, entities, events,
  businessConferenceParticipants, ratings, denyList, recommendations, order,
  ratingEnabled, broadcasts, setMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees');
  const [expanded, setExpanded] = useState(false);
  const { HYBRID } = ATTENDANCE_MODE;

  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);
  const currentAttendee = getCurrentAttendee(entities);
  const hybridEvent = eventAttendanceMode === HYBRID;

  const threeColumnsLayout = (ratingEnabled && !hybridEvent) || (!ratingEnabled && hybridEvent);
  const fourColumnsLayout = ratingEnabled && hybridEvent;

  return (
    <>
      <div className={clsx(classes.listViewHeader, threeColumnsLayout
        && classes.gridThreeColumns, fourColumnsLayout && classes.gridFourColumns)}
      >
        <Typography className={classes.dataTitle} variant="body2">{t('data.attendee')}</Typography>
        <Typography className={classes.dataTitle} variant="body2">{t('data.attendeeType')}</Typography>
        {hybridEvent && (
          <Typography className={classes.dataTitle} variant="body2">{t('data.attendanceMode')}</Typography>
        )}
        {ratingEnabled && (
          <Typography className={classes.dataTitle} variant="body2">
            {t('data.ratingTableTitle')} <Helper message={t('info.ratingHelp')} small inline />
          </Typography>
        )}
      </div>
      {order?.map((attendeeId) => {
        const currentPerson = getCurrentPerson(
          attendees[attendeeId], businessConferenceParticipants, attendeeTypes,
          attendeeTypeCustomFields, ratings, denyList, recommendations
        );
        return (
          <PersonListCard
            key={attendeeId}
            ratingEnabled={ratingEnabled}
            broadcasts={broadcasts}
            expanded={expanded}
            setExpanded={setExpanded}
            currentAttendee={currentAttendee}
            person={currentPerson}
            attendeeTypeCustomFields={attendeeTypeCustomFields}
            setMessage={setMessage}
          />
        );
      })}
    </>
  );
};

ListViewTable.propTypes = {
  entities: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ),
  links: PropTypes.shape({
    next: PropTypes.string,
  }),
  denyList: PropTypes.objectOf(CustomPropTypes.deniedParticipant),
  recommendations: PropTypes.arrayOf(CustomPropTypes.recommendation),
  ratings: PropTypes.objectOf(CustomPropTypes.rating),
  order: PropTypes.arrayOf(PropTypes.string).isRequired,
  setMessage: PropTypes.func.isRequired,
  ratingEnabled: PropTypes.bool,
  broadcasts: PropTypes.objectOf(PropTypes.func).isRequired,
};

ListViewTable.defaultProps = {
  attendees: {},
  attendeeTypes: {},
  businessConferenceParticipants: {},
  attendeeTypeCustomFields: {},
  links: {},
  ratings: {},
  denyList: {},
  recommendations: [],
  ratingEnabled: false,
};

const mapStateToProps = ({
  entities,
  entities: {
    events,
    attendeeTypeCustomFields,
    attendees,
    attendeeTypes,
    businessConferenceParticipants,
    businessConferenceRatedPossibleMeetings: ratings,
    businessConferenceParticipantBlacklists: denyList,
  },
  meta: {
    recommendations,
  },
}) => ({
  entities,
  events,
  attendeeTypeCustomFields,
  attendeeTypes,
  attendees,
  businessConferenceParticipants,
  ratings,
  denyList,
  recommendations,
});

export default connect(mapStateToProps)(ListViewTable);
