import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from '../Switch';
import { toggleReactions } from '../../actions/app';

const ReactionSwitch = ({ mutedReactions, toggleReactions: dispatchToggleReactions }) => (
  <Switch
    color="secondary"
    onChange={dispatchToggleReactions}
    checked={mutedReactions}
  />
);

ReactionSwitch.propTypes = {
  mutedReactions: PropTypes.bool.isRequired,
  toggleReactions: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: {
    mutedReactions,
  },
}) => ({
  mutedReactions,
});

export default connect(mapStateToProps, { toggleReactions })(ReactionSwitch);
