import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SelectButton from 'eventtia-ui-components/lib/SelectButton';
import Helper from 'eventtia-ui-components/lib/Helper';
import { setAttendanceType } from '../../actions/app';
import SubpageTitle from '../../components/SubpageTitle';
import TabletExitButton from '../../components/TabletExitButton';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import { getAttendanceModeForCurrentAttendee } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import useDidUpdate from '../../hooks/useDidUpdate';
import useRoutes from '../../hooks/useRoutes';
import ReactionsSettings from '../../components/ReactionsSettings';

const { PHYSICAL, VIRTUAL, HYBRID } = ATTENDANCE_MODE;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 785,
    maxWidth: '100%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  itemTitle: {
    margin: theme.spacing(2, 2, 2, 0),
    flex: 1,
  },
  selectContainer: {
    display: 'flex',
  },
  selectVenue: {
    marginRight: theme.spacing(1),
    width: 150,
  },
}));

const Settings = ({
  entities,
  attendanceType: userAttendanceType,
  setAttendanceType: dispatchSetAttendanceType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('settings');

  const attendanceMode = getAttendanceModeForCurrentAttendee(entities);

  const selectAttendanceType = [{
    label: t('attendanceType.virtually'),
    onClick: () => {
      dispatchSetAttendanceType(VIRTUAL);
    },
  }, {
    label: t('attendanceType.inPerson'),
    onClick: () => {
      dispatchSetAttendanceType(PHYSICAL);
    },
  }];

  const { goTo } = useRoutes();
  useDidUpdate(() => {
    goTo('stage');
  }, [userAttendanceType]);

  return (
    <div className={classes.root}>
      <TabletExitButton />
      <SubpageTitle text={t('sidebar:subpages.settings')} />
      <Typography className={classes.sectionTitle} variant="subtitle2">
        {t('interfaceSettings')}
      </Typography>
      <Divider />
      <div className={classes.itemContainer}>
        <Typography className={classes.itemTitle} variant="subtitle2">
          {t('darkMode')}
        </Typography>
        <DarkModeSwitch />
      </div>
      <div className={classes.itemContainer}>
        <Typography className={classes.itemTitle} variant="subtitle2">
          {t('disableReactions')}
        </Typography>
        <ReactionsSettings />
      </div>
      {attendanceMode === HYBRID && (
        <div className={classes.itemContainer}>
          <Typography className={classes.itemTitle} variant="subtitle2">
            {t('attendanceTypeQuestion')}
          </Typography>
          <div className={classes.selectContainer}>
            <div className={classes.selectVenue}>
              <SelectButton
                label={
                  userAttendanceType === VIRTUAL ? t('attendanceType.virtually') : t('attendanceType.inPerson')
                }
                items={selectAttendanceType}
              />
            </div>
            <Helper inline message={t('settingsHybridSelectorHelper')} />
          </div>
        </div>
      )}
    </div>
  );
};

Settings.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  attendanceType: PropTypes.oneOf([PHYSICAL, VIRTUAL]),
  setAttendanceType: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  attendanceType: '',
};

const mapStateToProps = ({
  entities,
  app: {
    attendanceType,
  },
}) => ({
  entities,
  attendanceType,
});

export default connect(mapStateToProps, { setAttendanceType })(Settings);
