import {
  SET_ACTIVE_SUBPAGE,
  GO_TO_PREVIOUS_SUBPAGE,
  SET_ATTENDANCE_TYPE,
  SET_FIREBASE_SESSION_ID,
  TOGGLE_DARK_MODE,
  TOGGLE_MUTE_REACTIONS,
} from '../../actions/app';
import subpages from '../../helpers/subpages';
import { isInDarkMode } from '../../helpers/darkMode';

export const initialState = {
  activeSubpage: { module: subpages.mainStage.name },
  subpageHistory: [],
  attendanceType: '',
  darkMode: isInDarkMode(),
  firebaseSessionId: '',
  mutedReactions: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_SUBPAGE: {
      const { subpage: newSubpage } = action;
      const previousSubpage = state.activeSubpage;
      const subpageHistory = [
        subpages.mainStage.name,
        subpages.Home.name,
      ].includes(newSubpage.module) ? [] : [...state.subpageHistory, previousSubpage];
      return {
        ...state,
        activeSubpage: newSubpage,
        subpageHistory,
      };
    }
    case GO_TO_PREVIOUS_SUBPAGE: {
      const newHistory = [...state.subpageHistory];
      const newSubpage = newHistory.pop();
      return {
        ...state,
        activeSubpage: newSubpage || initialState.activeSubpage,
        subpageHistory: newHistory,
      };
    }
    case SET_ATTENDANCE_TYPE: {
      const { attendanceType: newAttendanceType } = action;
      return {
        ...state,
        attendanceType: newAttendanceType,
      };
    }
    case TOGGLE_DARK_MODE: {
      return {
        ...state,
        darkMode: action.darkMode,
      };
    }
    case SET_FIREBASE_SESSION_ID: {
      return {
        ...state,
        firebaseSessionId: action.firebaseSessionId,
      };
    }
    case TOGGLE_MUTE_REACTIONS: {
      return {
        ...state,
        mutedReactions: !state.mutedReactions,
      };
    }
    default:
      return state;
  }
};
